<template>
  <div class="bg-white box-shadow border-r px-20 pb-20">
    <div class="d-flex-center px-15 border-bottom">
      <h4 class="mr-auto color-333">{{ settings[locel] }}</h4>
      <!-- <el-tag effect="plain" hit :type="verifyState[formData.verified][0]">
        {{
          verifyState[formData.verified][1]
        }}
      </el-tag> -->
      <el-tag effect="plain" hit :type="'info'">
        {{ verifyState_text }}
        <!-- {{
          verifyState[formData.verified][1]
        }} -->
      </el-tag>
    </div>
    <div v-loading="loading" class="p-20">
      <el-form
        ref="ruleForm"
        class="input-form mx-auto"
        :model="formData"
        :rules="formData.verified >= 2 ? [] : rules"
      >
        <el-form-item prop="id_name">
          <el-input
            v-model="formData.id_name"
            :readonly="formData.verified >= 2"
            prefix-icon="el-icon-postcard"
            :placeholder="placeholder[locel][0]"
          />
        </el-form-item>

        <!-- 新增联系方式 -->
        <el-form-item prop="phone">
          <el-input
            v-model="formData.phone"
            :readonly="formData.verified >= 2"
            prefix-icon="el-icon-postcard"
            :placeholder="$t('phoneVerified')"
          />
        </el-form-item>

        <el-form-item prop="id_number">
          <el-input
            v-model="formData.id_number"
            :readonly="formData.verified >= 2"
            prefix-icon="el-icon-postcard"
            :placeholder="placeholder[locel][1]"
          />
        </el-form-item>
        <el-form-item v-if="formData.verified < 2" prop="id_image_a">
          <el-upload
            action=""
            accept="image/*"
            :multiple="false"
            :show-file-list="false"
            :before-upload="onUploadImageA"
            class="w-100 text-center"
          >
            <el-button
              type="warning"
              icon="el-icon-upload"
              plain
              class="bg-t w-100"
              >{{ upload_image[locel][0] }}</el-button
            >
          </el-upload>
        </el-form-item>
        <el-form-item v-if="formData.id_image_a" class="d-flex-center">
          <el-image
            class="mx-auto voucher-img"
            :src="formData.id_image_a"
            fit="cover"
          />
        </el-form-item>
        <el-form-item v-if="formData.verified < 2" prop="id_image_b">
          <el-upload
            action=""
            accept="image/*"
            :multiple="false"
            :show-file-list="false"
            :before-upload="onUploadImageB"
            class="w-100 text-center"
          >
            <el-button
              type="warning"
              icon="el-icon-upload"
              plain
              class="bg-t w-100"
              >{{ upload_image[locel][1] }}</el-button
            >
          </el-upload>
        </el-form-item>
        <el-form-item v-if="formData.id_image_b" class="d-flex-center">
          <el-image
            class="mx-auto voucher-img"
            :src="formData.id_image_b"
            fit="cover"
          />
        </el-form-item>
        <el-form-item v-if="formData.verified < 2">
          <el-button
            type="primary"
            icon="el-icon-s-promotion"
            class="w-100"
            native-type="button"
            @click="submitForm"
            >{{ submit[locel] }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      locel: localStorage.getItem("locale") || "en-US",
      loadingData: true,
      loading: false,
      formData: {
        id_name: null,
        id_number: null,
        id_image_a: null,
        id_image_b: null,
        id_image_a_fileName: null,
        id_image_b_fileName: null,

        phone: null,
      },
      // verifyState: [
      //     ['info', 'Unsubmitted'],
      //     ['danger', 'Fail'],
      //     ['primary', 'Pending'],
      //     ['success', 'Success']
      // ],

      // verifyState: [
      //   ["info", this.$t("account.verify_state.unsubmitted")],
      //   ["danger", this.$t("account.verify_state.fail")],
      //   ["primary", this.$t("account.verify_state.checking")],
      //   ["success", this.$t("account.verify_state.success")],
      // ],

      verifyState_text: "",

      settings: {
        "zh-TW": "KYC 認證",
        "en-US": "KYC Verify",
        Latin: "KYC certificationem",

        Thailand: "การรับรอง KYC",
        Korea: "KYC 인증",
        Japan: "KYC 認証",
      },
      placeholder: {
        "zh-TW": ["輸入身份證名稱", "輸入身份證號碼"],
        "en-US": ["Enter ID Name", "Enter ID Number"],
        Latin: ["Intra ID card nomen tuum", "Intra ID numerus"],

        Thailand: ["กรอกชื่อบัตรประชาชน", "กรอกหมายเลขบัตรประชาชน"],
        Korea: ["신분증 이름 입력", "주민등록번호 입력"],
        Japan: ["ID名を入力", "身分証明書番号の入力"],
      },
      upload_image: {
        "zh-TW": ["身份證正面圖片", "身份證反面圖片"],
        "en-US": ["ID card front picture", "Reverse picture of ID card"],
        Latin: ["ID card ante picturam", "Reverse picture of ID card"],

        Thailand: ["ภาพด้านหน้าของบัตรประชาชน", "กลับภาพบัตรประชาชน"],
        Korea: ["신분증 정면 사진", "신분증 뒷면 사진"],
        Japan: ["身分証明書の正面図", "身分証明書の裏の画像"],
      },
      submit: {
        "zh-TW": "提交",
        "en-US": "Submit",
        Latin: "Submittere",

        Thailand: "ส่ง",
        Korea: "제출",
        Japan: "送信",
      },
      rules: {
        id_name: [
          {
            required: true,
            trigger: "blur",
            message: this.$t("account.verify_page.rules1"),
          },
        ],
        phone: [
          {
            required: true,
            trigger: "blur",
            message: this.$t("phoneVerifiederr"),
          },
        ],
        id_number: [
          {
            required: true,
            trigger: "blur",
            message: this.$t("account.verify_page.rules2"),
          },
        ],
        // id_image_a: [
        //     { required: true, type: 'url', trigger: 'blur' }
        // ],
        // id_image_b: [
        //     { required: true, type: 'url', trigger: 'blur' }
        // ]
      },
    };
  },
  created: function () {
    this.fatchData();
  },
  methods: {
    //更换接口获取实名相关数据
    fatchData: async function () {
      // const { data } = await this.$request.get('user/verify')
      const { data } = await this.$request.post("v1/user/verify");
      this.formData = data;
      // verifyState: [
      //   ["info", this.$t("account.verify_state.unsubmitted")],
      //   ["danger", this.$t("account.verify_state.fail")],
      //   ["primary", this.$t("account.verify_state.checking")],
      //   ["success", this.$t("account.verify_state.success")],
      // ],
      if (this.formData.verified == 0) {
        this.verifyState_text = this.$t("account.verify_state.unsubmitted");
      }
      if (this.formData.verified == 1) {
        this.verifyState_text = this.$t("account.verify_state.fail");
      }
      if (this.formData.verified == 2) {
        this.verifyState_text = this.$t("account.verify_state.checking");
      }
      if (this.formData.verified == 3) {
        this.verifyState_text = this.$t("account.verify_state.success");
      }

      this.loading = false;
    },

    imgCompress: async function (file) {
      // 将文件转img对象
      const img = await this.fileToImg(file);
      return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        // 获取文件宽高比例
        const { width: originWidth, height: originHeight } = img;
        // // 自定义等比例缩放宽高属性，这里我用的是固定600宽度，高度是等比例缩放
        const scale = +(originWidth / originHeight).toFixed(2); // 比例取小数点后两位)
        const targetWidth = 600; // 固定宽
        const targetHeight = Math.round(600 / scale); // 等比例缩放高

        canvas.width = targetWidth;
        canvas.height = targetHeight;
        context.clearRect(0, 0, targetWidth, targetHeight);
        // canvas重新绘制图片
        context.drawImage(img, 0, 0, targetWidth, targetHeight);
        // canvas转二进制对象转文件对象，返回
        const type = "image/png";
        canvas.toBlob(function (blob) {
          const f = new File([blob], file.name, {
            type,
            lastModified: file.lastModified,
          });
          resolve(f);
        }, type);
      });
    },

    fileToImg(file) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        const reader = new FileReader();
        reader.onload = function (e) {
          img.src = e.target.result;
        };
        reader.onerror = function (e) {
          reject(e);
        };
        reader.readAsDataURL(file);
        img.onload = function () {
          resolve(img);
        };
        img.onerror = function (e) {
          reject(e);
        };
      });
    },

    onUploadImageA: function (file) {
      return this.onUploadImage(file, "id_image_a");
    },
    onUploadImageB: function (file) {
      return this.onUploadImage(file, "id_image_b");
    },
    onUploadImage: function (file, key) {
      let that = this;
      if (file.size > 1024 * 1024 * 5) {
        this.$message({
          message: that.$t("imageLimit"),
          type: "error",
        });
        return false;
      }
      //   var reader = new FileReader();
      //   reader.readAsDataURL(file);
      //   reader.onload = function () {
      //     // console.log(reader.result); //获取到base64格式图片
      //     that.formData[key] = reader.result; //用于图片预览
      //   };

      let sendData = new FormData();
      if (file.size > 1024 * 1024 * 2) {
        this.$imgCompress.imgCompress(file)
        .then(f => {
          console.log(f)
          sendData.append("image", f);
          that.$request
            .post("v1/uploadImage", sendData)
            .then(({ data }) => {
              that.formData[key] = data.url;
              that.formData[key + "_fileName"] = data.fileName;
            })
            .catch(() => {});
        })
      }else{
        sendData.append("image", file);
        that.$request
          .post("v1/uploadImage", sendData)
          .then(({ data }) => {
            that.formData[key] = data.url;
            that.formData[key + "_fileName"] = data.fileName;
          })
          .catch(() => {});
      }
    },
    submitForm: async function () {
      if (!this.formData.id_image_a) {
        this.$message({
          message: this.$t("id_image_a"),
          type: "error",
        });
        return false;
      }
      if (!this.formData.id_image_b) {
        this.$message({
          message: this.$t("id_image_b"),
          type: "error",
        });
        return false;
      }
      try {
        await this.$refs.ruleForm.validate();
        this.loading = true;
        // await this.$request.post('user/verify_submit', this.formData)

        let _data = this.formData;
        _data.id_image_a = this.formData.id_image_a_fileName;
        _data.id_image_b = this.formData.id_image_b_fileName;

        let $data = await this.$request.post("v1/user/verifySubmit", _data);

        let that = this;
        this.$message({
          showClose: true,
          type: "success",
          duration: 2000,
          message: $data.message,
          onClose: function () {
            that.formData.verified = 2;
            that.loading = false;
            that.fatchData();
          },
        });
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.input-form {
  width: 400px;
}
.voucher-img {
  width: 320px;
  height: 180px;
}
</style>